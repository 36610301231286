.body {
    font-size: 0.8125em;
    margin-left: 220px;
    padding: 20px;    
}

.content {
    width: 90%;
    position: absolute;
    left: 190px;
}

#menu {
    position: fixed;
    top: 0;    
    left: 0;
    bottom: 0;
    width: 180px;
    padding: 20px;
    border-right: solid 1px #cacaca;
	background: #0080B9;
}

#menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#menu li + li {
    margin-top: 5px;
}

#menu li ul {
    margin-top: 10px;
}

#menu li li {
    padding-left: 15px;
}

#menu a {
    color: inherit;
    text-decoration: none;
}
#menu a:hover {
    text-decoration: underline;
}

h1 {
    margin: 0 0 20px 0;
}

h2 {
    margin: 25px 0 15px 0;
    padding: 0 0 5px 0;
    border-bottom: solid 2px #cacaca;
}

h3 {
    margin: 20px 0 15px 0;
    padding: 0 0 5px 0;
    border-bottom: solid 1px #cacaca;
}

th, td {
    vertical-align: top;
    padding: 5px;
    text-align: left;
    white-space: pre-line;
    border: solid 1px #cacaca;
    background: #fafafa;
}

.url {
    padding: 10px;
    white-space: pre-wrap;
    border: solid 1px #cacaca;
    background: #fafafa;
}

.code {
    padding: 10px;
    white-space: pre-wrap;
    border: solid 1px #cacaca;
    background: #fafafa;
}

@media screen and (max-width: 400px) {
    
}
